<template>
  <div>
    <h4>Datos de certificado médico y médico certificante</h4>
    <div class="cols2">
      <pd-input
        v-model="certificadoMedico.fecha_certificado"
        id="certificado_medico_fecha_certificado"
        label="Fecha del certificado médico"
        type="date"
        readonly
      />
      <pd-input
        v-model="certificadoMedico.gestacion"
        id="gestacion"
        label="Gestación en semanas"
        type="number"
        readonly
      />
      <div class="form-group">
        <label class="form-label">Tipo de fecha de parto</label>
        <select
          id="tipo_novedad"
          class="form-control readonly"
          disabled
          v-model="certificadoMedico.tipo_fecha_parto"
        >
          <option :value="null">-</option>
          <option :value="TipoFechaPartoConstants.PROBABLE">{{ TipoFechaPartoConstants.PROBABLE }}</option>
          <option :value="TipoFechaPartoConstants.REAL">{{ TipoFechaPartoConstants.REAL }}</option>
        </select>
      </div>
      <pd-input
        v-model="certificadoMedico.fecha_parto"
        id="certificado_medico_fecha_parto"
        label="Fecha de parto"
        type="date"
        readonly
      />

      <pd-input
        v-model="certificadoMedico.fecha_interrupcion_embarazo"
        id="certificado_medico_fecha_interrupcion_embarazo"
        label="Fecha de interrupcion de parto"
        type="date"
        readonly
      />
      <FileLink text="Ver certificado médico" :file-path="certificadoMedico.certificado"/>
    </div>
  </div>
</template>
<script>
import {TipoFechaPartoConstants} from "../constants/tipoFechaParto";
import FileLink from "@/components/elements/FileLink.vue";

export default {
  name: "ShowPercepcionMaternidadCertificado",
  components: {FileLink},
  computed: {
    TipoFechaPartoConstants() {
      return TipoFechaPartoConstants
    }
  },
  props:{
    certificadoMedico: {
      type: {},
      required: true
    }
  }
}
</script>

<style scoped>

</style>
