<template>
  <div class="w-100">
    <label class="form-label">{{label}}</label>
    <a
      :href="`${filePathLink}`"
      class="d-flex left btn-flat-dark text-small form-control"
      style="padding: .5rem; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; flex-wrap: nowrap"
      target="_blank"
    >
      <icon style="height: 16px" feather="file"/>
      <span class="px-1" style="margin-top: .25rem">{{ text }}</span>
    </a>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "FileLink",
  props: {
    label: {
      type: String,
      default: ''
    },
    filePath: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    filePathLink() {
      return `${axios.defaults.baseURL}/${this.filePath}`
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>
