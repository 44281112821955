<template>
  <div>
    <template v-if="asignacionFamiliar">
      <div class="card">
        <h3 class="card-title">Ver asignación familiar</h3>
        <div class="">
          <span class="mr-1 text-bold">Estado:</span>
          <span class="badge" :class="{
            'primary':asignacionFamiliar.estado === TipoEstadoAsignacionConstants.EN_REVISION,
            'warning': asignacionFamiliar.estado === TipoEstadoAsignacionConstants.CON_OBSERVACIONES,
            'success': asignacionFamiliar.estado === TipoEstadoAsignacionConstants.APROBADO,
            'danger': asignacionFamiliar.estado === TipoEstadoAsignacionConstants.RECHAZADO,
          }">
            {{ asignacionFamiliar.estado }}
          </span>
        </div>
        <div class="d-flex left mt-1" v-if="asignacionFamiliar.ddjj_file">

          <span class="mr-1 text-bold">DDJJ:</span>
          <a class="btn btn-sm btn-info d-flex center" download="" target="_blank"
             :href="filePathLink"
             style="text-decoration: none">
            <icon feather="download" style="height: 12px"></icon>
            Descargar DDJJ
          </a>

        </div>
        <div class="mt-2 alert-outline-warning" v-if="asignacionFamiliar.observaciones">
          <span class="mr-1 text-bold">Observaciones:</span>
          <p class="mb-0">{{ asignacionFamiliar.observaciones }}</p>
        </div>
        <template v-if="asignacionFamiliar.percepcion_cargas_familia">

          <ShowCargasDeFamiliaComponent
            :percepcion-cargas-familia="asignacionFamiliar.percepcion_cargas_familia"
          />
        </template>
        <template v-if="asignacionFamiliar.percepcion_maternidad">
          <ShowPercepcionMaternidad
            :percepcion-maternidad="asignacionFamiliar.percepcion_maternidad"
          />
        </template>
      </div>
      <div class="card"
           v-if="user.id === asignacionFamiliar.user_id && (
             asignacionFamiliar.estado === TipoEstadoAsignacionConstants.CON_OBSERVACIONES ||
             asignacionFamiliar.estado === TipoEstadoAsignacionConstants.APROBADO
             )">
        <router-link :to="{name: 'EditarPercepcionCargasFamilia', params: {id: asignacionFamiliar.id}}"
                     class="btn btn-warning" style="text-decoration: none">Rectificar
        </router-link>
      </div>
      <form
        @submit.prevent="updateState"
        class="card"
        v-if="
        user.tipo === 'dev' ||
         (user.id !== asignacionFamiliar.user_id &&
          user.tipo === 'RRHH' &&
           (
             stateChanged ||
             (!stateChanged && asignacionFamiliar.estado !== TipoEstadoAsignacionConstants.RECHAZADO)
           )
          )"
      >
        <h4>Modificar estado</h4>
        <div class="form-group">
          <label class="form-label">Observaciones *</label>
          <textarea
            class="form-control w-100"
            placeholder="Observaciones"
            style="resize: none;"
            rows="5"
            :value="asignacionFamiliar.observaciones"
            @input="update($event.target.value)"
          ></textarea>
        </div>
        <div class="form-group">
          <label class="form-label">Estado *</label>
          <select
            id="tipo_novedad"
            class="form-control"
            v-model="asignacionFamiliar.estado"
            :class="{'is-invalid': enviado && !asignacionFamiliar.estado}"
            @change="stateChanged = true"
          >
            <option :value="TipoEstadoAsignacionConstants.EN_REVISION">{{ TipoEstadoAsignacionConstants.EN_REVISION }}
            </option>
            <option :value="TipoEstadoAsignacionConstants.APROBADO">{{ TipoEstadoAsignacionConstants.APROBADO }}
            </option>
            <option :value="TipoEstadoAsignacionConstants.RECHAZADO">{{ TipoEstadoAsignacionConstants.RECHAZADO }}
            </option>
            <option :value="TipoEstadoAsignacionConstants.CON_OBSERVACIONES">
              {{ TipoEstadoAsignacionConstants.CON_OBSERVACIONES }}
            </option>
          </select>
          <small class="form-message text-danger" v-if="enviado && !asignacionFamiliar.estado">
            Éste campo es obligatorio
          </small>
        </div>
        <div class="mt-2">
          <button class="btn btn-right btn-primary">Guardar</button>
        </div>
      </form>
    </template>
  </div>
</template>
<script>
import {asignacionesFamiliaresApi} from "@/modules/percepcionesAsignacionFamiliar/services/asignacionesFamiliaresApi";
import {TipoPercepcionConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoPercepcion";
import {TipoEstadoAsignacionConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoEstadoAsignacion";
import ShowCargasDeFamiliaComponent
  from "@/modules/percepcionesAsignacionFamiliar/components/ShowCargasDeFamiliaComponent.vue";
import Swal from "sweetalert2";
import ShowPercepcionMaternidad from "@/modules/percepcionesAsignacionFamiliar/components/ShowPercepcionMaternidad.vue";
import {mapState} from "vuex";
import axios from "axios";

export default {
  name: "VerAsignacionFamiliar",
  components: {ShowPercepcionMaternidad, ShowCargasDeFamiliaComponent},
  computed: {
    TipoEstadoAsignacionConstants() {
      return TipoEstadoAsignacionConstants
    },
    TipoPercepcionConstants() {
      return TipoPercepcionConstants
    },
    filePathLink() {
      return `${axios.defaults.baseURL}/asignaciones-familiares/${btoa(this.asignacionFamiliar?.id)}/ddjj-file`
    },
    ...mapState(["user"]),
  },
  data() {
    return {
      asignacionFamiliar: null,
      stateChanged: false,
      enviado: null
    }
  },
  mounted() {
    asignacionesFamiliaresApi.show(this.$route.params.id).then(asignacionFamiliar => this.asignacionFamiliar = asignacionFamiliar)
  },
  methods: {
    update(e) {
      this.asignacionFamiliar.observaciones = e
    },
    updateState() {
      this.enviado = true;
      const data = {
        estado: this.asignacionFamiliar.estado,
        observaciones: this.asignacionFamiliar.observaciones
      }
      asignacionesFamiliaresApi.updateState(this.asignacionFamiliar.id, data).then((response) => {
        if (response.success) {
          Swal.fire("Éxito", "Se guardó correctamente", "success");
          this.$router.push({name: "ListarPercepciones"});
        } else {
          Swal.fire("Error", "No se pudo guardar", "error");
        }
      })
        .catch((error) => {
          Swal.fire(
            "Error",
            `<p>Error de servidor</p><p>${error}</p>`,
            "error"
          );
        });
    }
  }
}
</script>

<style scoped>

</style>
