<template>
  <div>
    <h4>Maternidad down</h4>
    <div class="cols2">
      <div class="form-group">
        <label class="form-label">Tipo de novedad *</label>
        <select
          id="tipo_novedad"
          class="form-control readonly"
          disabled
          v-model="percepcionMaternidad.tipo_novedad"
        >
          <option :value="null">Seleccione una opción</option>
          <option :value="TipoNovedadConstants.ALTA">{{ TipoNovedadConstants.ALTA }}</option>
          <option :value="TipoNovedadConstants.BAJA">{{ TipoNovedadConstants.BAJA }}</option>
        </select>
      </div>

      <pd-input
        v-model="percepcionMaternidad.maternidad_down.fecha_fin_licencia"
        id="maternidad_down_fecha_fin_licencia"
        label="Fecha de fin de licencia por maternidad"
        type="date"
        placeholder="date"
        readonly
      />

      <pd-input
        v-model="percepcionMaternidad.maternidad_down.cuil_hijo"
        id="maternidad_down_cuil_hijo"
        label="Cuil hijo"
        placeholder="Cuil hijo"
        readonly
      />

      <pd-input
        v-model="percepcionMaternidad.maternidad_down.dni_hijo"
        id="maternidad_dni_cuil_hijo"
        label="DNI hijo"
        placeholder="DNI hijo"
        readonly
      />

      <pd-input
        v-model="percepcionMaternidad.maternidad_down.nombre_hijo"
        id="maternidad_down_nombre_hijo"
        label="Apellido y nombre completo de hijo"
        placeholder="Apellido y nombre completo de hijo"
        readonly
      />

      <div class="form-group">
        <label class="form-label">Tipo de partida *</label>
        <select
          id="tipo_partida"
          class="form-control readonly"
          disabled
          v-model="percepcionMaternidad.maternidad_down.tipo_partida"
        >
          <option :value="null">-</option>
          <option :value="TipoPartidaConstants.NACIMIENTO">{{ TipoPartidaConstants.NACIMIENTO }}</option>
          <option :value="TipoPartidaConstants.DEFUNCION">{{ TipoPartidaConstants.DEFUNCION }}</option>
        </select>

      </div>

      <pd-input
        v-model="percepcionMaternidad.maternidad_down.numero_partida"
        id="maternidad_down_numero_partida"
        label="N° de Acta/Partida/Certificado"
        placeholder="N° de Acta/Partida/Certificado"
        readonly
      />

      <pd-input
        v-model="percepcionMaternidad.maternidad_down.numero_tomo"
        id="maternidad_down_numero_tomo"
        label="N° de tomo"
        placeholder="N° de tomo"
        readonly
      />

      <pd-input
        v-model="percepcionMaternidad.maternidad_down.numero_folio"
        id="maternidad_down_numero_folio"
        label="N° de folio"
        placeholder="N° de folio"
        readonly
      />

      <FileLink text="Ver certificado médico" :file-path="percepcionMaternidad.certificado_medico.certificado"/>
    </div>
  </div>
</template>
<script>
import {TipoPartidaConstants} from "../constants/tipoPartida";
import {TipoNovedadConstants} from "../constants/tipoNovedad";
import FileLink from "@/components/elements/FileLink.vue";

export default {
  name: "ShowPercepcionMaternidadDown",
  components: {FileLink},
  computed: {
    TipoNovedadConstants() {
      return TipoNovedadConstants
    },
    TipoPartidaConstants() {
      return TipoPartidaConstants
    }
  },
  props:{
    percepcionMaternidad: {
      type: {},
      required: true
    }
  }
}
</script>

<style scoped>

</style>
