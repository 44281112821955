<template>
  <div>
    <div class="cols2">
      <div class="mt-1">
        <span class="mr-1 text-bold">Año:</span>
        <span class="badge">
            {{ percepcionCargasFamilia.year }}
          </span>
      </div>
    </div>
    <h4>Datos del/la trabajador/a</h4>
    <div class="cols3">
      <pd-input
        v-model="percepcionCargasFamilia.nombre_empleado"
        id="nombre_empleado"
        label="Nombre empleado *"
        placeholder="Nombre empleado"
        readonly="readonly"
        class-input="form-control readonly"
      />
      <pd-input
        v-model="percepcionCargasFamilia.dni_empleado"
        id="dni_empleado"
        label="DNI empleado *"
        placeholder="DNI empleado"
        readonly="readonly"
        class-input="form-control readonly"
      />
      <pd-input
        v-model="percepcionCargasFamilia.cuil_empleado"
        id="cuil_empleado"
        label="CUIL empleado *"
        placeholder="CUIL empleado"
        readonly="readonly"
        class-input="form-control readonly"
      />
      <div class="form-group">
        <label class="form-label">¿Tiene otro empleo? *</label>
        <select
          id="otro_empleo"
          class="form-control readonly"
          disabled
          v-model="percepcionCargasFamilia.otro_empleo"
        >
          <option :value="null">Seleccione una opción</option>
          <option :value="1">SI</option>
          <option :value="0">NO</option>
        </select>
      </div>
    </div>
    <template v-if="percepcionCargasFamilia.cargas_familia_otro_empleo">
      <h5 class="my-1 mx-1">Datos otro empleo</h5>
      <div class="cols2">

        <FileLink
          v-if="percepcionCargasFamilia.cargas_familia_otro_empleo.recibo_sueldo"
          :text="
              percepcionCargasFamilia.cargas_familia_otro_empleo.recibo_sueldo_original_name ?
              percepcionCargasFamilia.cargas_familia_otro_empleo.recibo_sueldo_original_name :
              'Ver recibo de sueldo'
          "
          label="Recibo de sueldo"
          :file-path="percepcionCargasFamilia.cargas_familia_otro_empleo.recibo_sueldo"
        />
        <div></div>
        <pd-input
          v-model="percepcionCargasFamilia.cargas_familia_otro_empleo.cuit_empleador"
          id="empleo_progenitor_cuit_empleador"
          label="CUIT empleador *"
          type="text"
          readonly
          class-input="form-control readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.cargas_familia_otro_empleo.razon_social_empleador"
          id="empleo_progenitor_razon_social_empleador"
          label="Razón social empleador *"
          type="text"
          readonly
          class-input="form-control readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.cargas_familia_otro_empleo.calle_domicilio_empleador"
          id="cargas_familia_empleo_progenitor.calle_domicilio_empleador"
          label="Calle *"
          type="text"
          readonly
          class-input="form-control readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.cargas_familia_otro_empleo.numero_domicilio_empleador"
          id="cargas_familia_empleo_progenitor.numero_domicilio_empleador"
          label="Número *"
          type="text"
          readonly
          class-input="form-control readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.cargas_familia_otro_empleo.piso_domicilio_empleador"
          id="cargas_familia_empleo_progenitor.piso_domicilio_empleador"
          label="Piso"
          type="text"
          readonly
          class-input="form-control readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.cargas_familia_otro_empleo.dpto_domicilio_empleador"
          id="cargas_familia_empleo_progenitor.dpto_domicilio_empleador"
          label="Dpto"
          type="text"
          readonly
          class-input="form-control readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.cargas_familia_otro_empleo.codigo_postal_empleador"
          id="cargas_familia_empleo_progenitor.codigo_postal_empleador"
          label="Código postal *"
          type="text"
          readonly
          class-input="form-control readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.cargas_familia_otro_empleo.localidad_empleador"
          id="cargas_familia_empleo_progenitor.localidad_empleador"
          label="Localidad *"
          type="text"
          readonly
          class-input="form-control readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.cargas_familia_otro_empleo.provincia_empleador"
          id="cargas_familia_empleo_progenitor.provincia_empleador"
          label="Provincia *"
          type="text"
          readonly
          class-input="form-control readonly"
        />
      </div>
      <div class="cols2 mt-1">
        <div class="form-group">
          <label class="form-label">¿Percibe asignaciones? *</label>
          <select
            id="percibe_asignaciones"
            class="form-control readonly"
            v-model="percepcionCargasFamilia.cargas_familia_otro_empleo.percibe_asignaciones"
            disabled
          >
            <option :value="null">Seleccione una opción</option>
            <option :value="1">SI</option>
            <option :value="0">NO</option>
          </select>
        </div>
        <FileLink
          v-if="percepcionCargasFamilia.cargas_familia_otro_empleo.certificado_no_percepcion"
          :text="
              percepcionCargasFamilia.cargas_familia_otro_empleo.certificado_no_percepcion_original_name ?
              percepcionCargasFamilia.cargas_familia_otro_empleo.certificado_no_percepcion_original_name :
              'Ver certificado de no percepción'
          "
          label="Certificado de no percepción"
          :file-path="percepcionCargasFamilia.cargas_familia_otro_empleo.certificado_no_percepcion"
        />
      </div>
    </template>
    <div class="cols2 mt-2">
      <div class="form-group">
        <label class="form-label">¿Trabaja en forma independiente (Autónomo/Monotributista)? *</label>
        <select
          id="percibe_asignaciones"
          class="form-control readonly"
          v-model="percepcionCargasFamilia.trabajador_independiente"
          disabled
        >
          <option :value="null">Seleccione una opción</option>
          <option :value="1">SI</option>
          <option :value="0">NO</option>
        </select>
      </div>
      <FileLink
        v-if="percepcionCargasFamilia.ultima_ddjj"
        :text="
              percepcionCargasFamilia.ultima_ddjj_original_name ?
              percepcionCargasFamilia.ultima_ddjj_original_name :
              'Ver Última DDJJ'
          "
        label="Última DDJJ"
        :file-path="percepcionCargasFamilia.ultima_ddjj"
      />
    </div>


    <template v-if="percepcionCargasFamilia.percepcion_cargas_familia_progenitor">
      <h4>Datos del otro progenitor</h4>
      <div class="cols3">
        <pd-input
          v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.nombre_progenitor"
          id="nombre_progenitor"
          label="Nombre progenitor *"
          placeholder="Nombre progenitor"
          class-input="form-control readonly"
          readonly="readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.dni_progenitor"
          id="dni_progenitor"
          label="DNI progenitor *"
          placeholder="DNI progenitor"
          class-input="form-control readonly"
          readonly="readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cuil_progenitor"
          id="cuil_progenitor"
          label="CUIL progenitor *"
          placeholder="CUIL progenitor"
          class-input="form-control readonly"
          readonly="readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.fecha_nacimiento_progenitor"
          id="fecha_nacimiento_progenitor"
          label="Fecha nacimiento progenitor *"
          placeholder="Fecha nacimiento progenitor"
          type="date"
          class-input="form-control readonly"
          readonly="readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.nacionalidad_progenitor"
          id="nacionalidad_progenitor"
          label="Nacionalidad progenitor *"
          placeholder="Nacionalidad progenitor"
          class-input="form-control readonly"
          readonly="readonly"
        />
      </div>
      <div class="cols2 mt-1">
        <pd-input
          v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.calle_domicilio_progenitor"
          id="calle_domicilio_progenitor"
          label="Calle *"
          placeholder="Calle"
          class-input="form-control readonly"
          readonly="readonly"
        />
        <div class="cols3" style="margin:0">
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.numero_domicili_progenitor"
            id="numero_domicilio_progenitor"
            label="Número *"
            placeholder="Número"
            class-input="form-control readonly"
            readonly="readonly"
          />
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.piso_domicilio_progenitor"
            id="piso_domicilio_progenitor"
            label="Piso"
            placeholder="Piso"
            class-input="form-control readonly"
            readonly="readonly"
          />
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.dpto_domicilio_progenitor"
            id="dpto_domicilio_progenitor"
            label="Dpto"
            placeholder="Dpto"
            class-input="form-control readonly"
            readonly="readonly"
          />

        </div>
      </div>
      <div class="cols2">
        <pd-input
          v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.localidad_progenitor"
          id="localidad_progenitor"
          label="Localidad progenitor *"
          placeholder="Localidad progenitor"
          readonly
          class-input="form-control readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.codigo_postal_progenitor"
          id="codigo_postal_progenitor"
          label="Código postal progenitor *"
          placeholder="Código postal progenitor"
          readonly
          class-input="form-control readonly"
        />
        <pd-input
          v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.provincia_progenitor"
          id="provincia_progenitor"
          label="Provincia progenitor *"
          placeholder="Provincia progenitor"
          readonly
          class-input="form-control readonly"
        />
        <div></div>
        <div class="form-group">
          <label class="form-label">¿Progenitor tiene empleo? *</label>
          <select
            id="otro_empleo"
            class="form-control readonly"
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.tiene_empleo_progenitor"
            disabled
          >
            <option :value="null">Seleccione una opción</option>
            <option :value="1">SI</option>
            <option :value="0">NO</option>
          </select>
        </div>

        <FileLink
          v-if="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.tiene_empleo_progenitor"
          :text="
              percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.recibo_sueldo_original_name ?
              percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.recibo_sueldo_original_name :
              'Ver recibo de sueldo'
          "
          label="Recibo de sueldo"
          :file-path="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.recibo_sueldo"
        />
      </div>
      <h5
        v-if="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor"
        class="my-1 mx-1"
      >
        Datos empleo de progenitor
      </h5>
      <template v-if="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor">
        <div class="cols2">
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.cuit_empleador"
            id="cuit_empleador_progenitor"
            label="CUIT Empleador *"
            type="text"
            readonly
            class-input="form-control readonly"
          />
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.razon_social_empleador"
            id="razon_social_empleador_progenitor"
            label="Razón social empleador *"
            type="text"
            readonly
            class-input="form-control readonly"
          />

          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.calle_domicilio_empleador"
            id="cargas_familia_empleo_progenitor_calle_domicilio_empleador"
            label="Domicilio"
            type="text"
            readonly
            class-input="form-control readonly"
          />
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.numero_domicilio_empleador"
            id="cargas_familia_empleo_progenitor_numero_domicilio_empleador"
            label="N°"
            type="text"
            readonly
            class-input="form-control readonly"
          />
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.piso_domicilio_empleador"
            id="cargas_familia_empleo_progenitor_piso_domicilio_empleador"
            label="Piso"
            type="text"
            readonly
            class-input="form-control readonly"
          />
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.dpto_domicilio_empleador"
            id="cargas_familia_empleo_progenitor_dpto_domicilio_empleador"
            label="Dpto"
            type="text"
            readonly
            class-input="form-control readonly"
          />
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.codigo_postal_empleador"
            id="cargas_familia_empleo_progenitor_codigo_postal_empleador"
            label="Código postal *"
            type="text"
            readonly
            class-input="form-control readonly"
          />
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.localidad_empleador"
            id="cargas_familia_empleo_progenitor_localidad_empleador"
            label="Localidad *"
            type="text"
            readonly
            class-input="form-control readonly"
          />
          <pd-input
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.provincia_empleador"
            id="cargas_familia_empleo_progenitor_provincia_empleador"
            label="Provincia *"
            type="text"
            readonly
            class-input="form-control readonly"
          />
        </div>
        <div class="cols2 mt-1">
          <div class="form-group">
            <label class="form-label">¿Percibe asignaciones familiares? *</label>
            <select
              id="empleo_progenitor_percibe_asignaciones"
              class="form-control readonly"
              disabled
              v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.percibe_asignaciones"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="1">SI</option>
              <option :value="0">NO</option>
            </select>
          </div>
          <FileLink
            v-if="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.certificado_no_percepcion"
            :text="
              percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.certificado_no_percepcion_original_name ?
              percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.certificado_no_percepcion_original_name :
              'Ver certificado de no percepción'
          "
            label="Certificado de no percepción"
            :file-path="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.cargas_familia_empleo_progenitor.certificado_no_percepcion"
          />
        </div>
      </template>
      <div class="cols2">
        <div class="form-group">
          <label class="form-label">¿Trabaja en forma independiente (Autónomo/Monotributista)? *</label>
          <select
            id="empleo_progenitor_trabajador_independiente"
            class="form-control readonly"
            disabled
            v-model="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.trabajador_independiente"
          >
            <option :value="null">Seleccione una opción</option>
            <option :value="1">SI</option>
            <option :value="0">NO</option>
          </select>
        </div>
        <FileLink
          v-if="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.ultima_ddjj"
          :text="
              percepcionCargasFamilia.percepcion_cargas_familia_progenitor.ultima_ddjj_original_name ?
              percepcionCargasFamilia.percepcion_cargas_familia_progenitor.ultima_ddjj_original_name :
              'Ver última DDJJ'
          "
          label="Última DDJJ"
          :file-path="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.ultima_ddjj"
        />
      </div>
      <template
        v-if="
          !empleo_progenitor_percibe_asignaciones
         ">
        <h5>Causas por las cuales el otro progenitor no percibe asignaciones</h5>
        <div class="w-100">
          <div class="my-1 cols3">
            <div class="form-control readonly">
              <label style="padding-left:.5rem;"
                     for="causas_no_percepcion">{{ percepcionCargasFamilia.percepcion_cargas_familia_progenitor.causas_no_percepcion_progenitor
                }}</label>
            </div>
          </div>
        </div>
      </template>
      <div class="cols2">
        <FileLink
          v-if="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.autorizacion_progenitor"
          :text="
              percepcionCargasFamilia.percepcion_cargas_familia_progenitor.autorizacion_progenitor_original_name ?
              percepcionCargasFamilia.percepcion_cargas_familia_progenitor.autorizacion_progenitor_original_name :
              'Ver autorización otro progenitor'
          "
          label="Autorización otro progenitor"
          :file-path="percepcionCargasFamilia.percepcion_cargas_familia_progenitor.autorizacion_progenitor"
        />
      </div>
    </template>

    <template v-if="
      !percepcionCargasFamilia.otro_empleo ||
      !otro_empleo_percibe_asignaciones ||
      !empleo_progenitor_percibe_asignaciones
    ">
      <h4>Hijos a cargo del trabajador/a menores de 18 años</h4>
      <table class="table-list">
        <thead>
        <tr>
          <th>CUIL</th>
          <th>Nombre</th>
          <th>Fecha de nacimiento</th>
          <th>Lugar</th>
          <th>DNI</th>
          <th>Discapacidad</th>
          <th>Acta de nacimiendo</th>
          <th>Certificado alumno regular</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="hijo in percepcionCargasFamilia.hijos" :key="hijo.cuil">
          <td>{{ hijo.cuil }}</td>
          <td>{{ hijo.nombre }}</td>
          <td>{{ formatDate(hijo.fecha_nacimiento) }}</td>
          <td>{{ hijo.lugar }}</td>
          <td>{{ hijo.dni }}</td>
          <td>
            <file-link
              v-if="hijo.discapacidad"
              :text="
                  hijo.certificado_discapacidad_original_name ?
                  hijo.certificado_discapacidad_original_name :
                  'Ver certificado de discapacidad'
              "
              :file-path="hijo.certificado_discapacidad"
            />
            <span v-else>NO</span>
          </td>
          <td>
            <file-link
              v-if="hijo.acta_nacimiento"
              :text="
                  percepcionCargasFamilia.acta_nacimiento_original_name ?
                  percepcionCargasFamilia.acta_nacimiento_original_name :
                  'Ver acta de nacimiento'
              "
              :file-path="hijo.acta_nacimiento"
            />
          </td>
          <td>
            <file-link
              v-if="hijo.certificado_alumno_regular"
              :text="
                  percepcionCargasFamilia.certificado_alumno_regular_original_name ?
                  percepcionCargasFamilia.certificado_alumno_regular_original_name :
                  'Ver certificado alumno regular'
              "
              :file-path="hijo.certificado_alumno_regular"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>
<script>
import axios from "axios";
import FileLink from "@/components/elements/FileLink.vue";
import {DateTime} from "luxon";

export default {
  name: "ShowCargasDeFamiliaComponent",
  components: {FileLink},
  computed: {
    otro_empleo_certificado_no_percepcion() {
      return `${axios.defaults.baseURL}/${this.percepcionCargasFamilia?.cargas_familia_otro_empleo.certificado_no_percepcion}`
    },
    otro_empleo_ultima_ddjj() {
      return `${axios.defaults.baseURL}/${this.percepcionCargasFamilia?.cargas_familia_otro_empleo.ultima_ddjj}`
    },
    empleo_progenitor_certificado_no_percepcion() {
      return `${axios.defaults.baseURL}/${this.percepcionCargasFamilia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor.certificado_no_percepcion}`
    },
    empleo_progenitor_ultima_ddjj() {
      return `${axios.defaults.baseURL}/${this.percepcionCargasFamilia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor.ultima_ddjj}`
    },
    otro_empleo_percibe_asignaciones() {
      return !!this.percepcionCargasFamilia?.cargas_familia_otro_empleo?.percibe_asignaciones
    },
    empleo_progenitor_percibe_asignaciones() {
      return !!this.percepcionCargasFamilia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.percibe_asignaciones
    }
  },
  props: {
    percepcionCargasFamilia: {
      required: true,
      type: {}
    }
  },
  methods: {
    formatDate(date) {
      if (date) {
        return DateTime.fromFormat(date, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')
      }
    }
  }
}
</script>

<style scoped>

</style>
