<template>
  <div>
    <h4>Tipo de percepción: {{percepcionMaternidad.tipo_percepcion}}</h4>
    <h4>Datos del/la trabajador/a</h4>
    <div class="cols2">
      <pd-input
        v-model="percepcionMaternidad.dni_empleado"
        id="dni_empleado"
        label="DNI empleado *"
        placeholder="DNI empleado"
        readonly="readonly"
      />
      <pd-input
        v-model="percepcionMaternidad.cuil_empleado"
        id="cuil_empleado"
        label="CUIL empleado *"
        placeholder="CUIL empleado"
        readonly="readonly"
      />
      <pd-input
        v-model="percepcionMaternidad.nombre_empleado"
        id="nombre_empleado"
        label="Nombre empleado *"
        placeholder="Nombre empleado"
        readonly="readonly"
      />
      <pd-input
        v-model="percepcionMaternidad.email_empleado"
        id="email_empleado"
        label="Email empleado *"
        placeholder="Email empleado"
        readonly="readonly"
      />
      <pd-input
        v-model="percepcionMaternidad.telefono_empleado"
        id="telefono_empleado"
        label="Teléfono empleado *"
        placeholder="Teléfono empleado"
        readonly="readonly"
      />

      <div class="form-group">
        <label class="form-label">Parentezco empleado *</label>
        <select
          id="parentezco_empleado"
          class="form-control readonly"
          disabled
          v-model="percepcionMaternidad.tipo_parentezco_empleado"
        >
          <option :value="null">Seleccione parentezco</option>
          <option :value="TipoParentezcoConstants.MADRE">{{ TipoParentezcoConstants.MADRE }}</option>
          <option :value="TipoParentezcoConstants.PADRE">{{ TipoParentezcoConstants.PADRE }}</option>
        </select>
      </div>
    </div>

    <div class="cols2 my-2">
      <div class="form-group">
        <label class="form-label">¿Posee una relación de cónyuge, conviviente o conviviente previsional con el otro
          progenitor? (Deberá acreditarla) *</label>
        <select
          id="es_conyuge"
          class="form-control readonly"
          disabled
          v-model="percepcionMaternidad.es_conyuge"
        >
          <option :value="1">SI</option>
          <option :value="0">NO</option>
        </select>
      </div>
      <FileLink
        v-if="percepcionMaternidad.es_conyuge" text="Ver acreditación cónyuge"
        :file-path="percepcionMaternidad.acreditacion_conyuge"
      />
    </div>

    <h4>Datos del otro progenitor</h4>
    <div class="cols2">
      <pd-input
        v-model="percepcionMaternidad.dni_progenitor"
        id="dni_progenitor"
        label="DNI progenitor *"
        placeholder="DNI progenitor"
        readonly
      />
      <pd-input
        v-model="percepcionMaternidad.cuil_progenitor"
        id="cuil_progenitor"
        label="CUIL progenitor *"
        placeholder="CUIL progenitor"
        readonly
      />
      <pd-input
        v-model="percepcionMaternidad.nombre_progenitor"
        id="nombre_progenitor"
        label="Nombre progenitor *"
        placeholder="Nombre progenitor"
        readonly
      />
      <pd-input
        v-model="percepcionMaternidad.email_progenitor"
        id="email_progenitor"
        label="Email progenitor *"
        placeholder="Email progenitor"
        readonly
      />
      <pd-input
        v-model="percepcionMaternidad.telefono_progenitor"
        id="telefono_progenitor"
        label="Teléfono progenitor *"
        placeholder="Teléfono progenitor"
        readonly
      />
      <div class="form-group">
        <label class="form-label">Parentezco progenitor *</label>
        <select
          id="parentezco_progenitor"
          class="form-control readonly"
          disabled
          v-model="percepcionMaternidad.tipo_parentezco_progenitor"
        >
          <option :value="null">Seleccione parentezco</option>
          <option :value="TipoParentezcoConstants.MADRE">{{ TipoParentezcoConstants.MADRE }}</option>
          <option :value="TipoParentezcoConstants.PADRE">{{ TipoParentezcoConstants.PADRE }}</option>
        </select>
      </div>
    </div>
    <ShowPercepcionMaternidadMaternidad
      v-if="percepcionMaternidad.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD"
      :percepcion-maternidad="percepcionMaternidad"
    />
    <ShowPercepcionMaternidadDown :percepcion-maternidad="percepcionMaternidad"/>
    <ShowPercepcionMaternidadCertificado
      v-if="
        percepcionMaternidad.certificado_medico &&
        (
          percepcionMaternidad.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD ||
          percepcionMaternidad.tipo_percepcion === TipoPercepcionConstants.PRENATAL
        )
      "
      :certificado-medico="percepcionMaternidad.certificado_medico"
    />
  </div>
</template>
<script>
import {TipoPercepcionConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoPercepcion";
import ShowPercepcionMaternidadMaternidad
  from "@/modules/percepcionesAsignacionFamiliar/components/ShowPercepcionMaternidadMaternidad.vue";
import FileLink from "@/components/elements/FileLink.vue";
import {TipoParentezcoConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoParentezco";
import axios from "axios";
import ShowPercepcionMaternidadCertificado
  from "@/modules/percepcionesAsignacionFamiliar/components/ShowPercepcionMaternidadCertificado.vue";
import ShowPercepcionMaternidadDown
  from "@/modules/percepcionesAsignacionFamiliar/components/ShowPercepcionMaternidadDown.vue";

export default {
  name: "ShowPercepcionMaternidad",
  components: {
    ShowPercepcionMaternidadDown,
    ShowPercepcionMaternidadCertificado, FileLink, ShowPercepcionMaternidadMaternidad},
  computed: {
    TipoPercepcionConstants() {
      return TipoPercepcionConstants
    },
    TipoParentezcoConstants() {
      return TipoParentezcoConstants
    },
    acreditacion_conyuge() {
      return `${axios.defaults.baseURL}/${this.percepcionMaternidad.acreditacion_conyuge}`
    }
  },
  props: {
    percepcionMaternidad: {
      type: {},
      required: true
    }
  }
}
</script>

<style scoped>

</style>
