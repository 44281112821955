<template>
  <div>
    <h4>Licencia por maternidad</h4>
    <div class="cols2">
      <div class="form-group">
        <label class="form-label">Tipo de novedad *</label>
        <select
          id="tipo_novedad"
          class="form-control readonly"
          disabled
          v-model="percepcionMaternidad.tipo_novedad"
        >
          <option :value="null">Seleccione una opción</option>
          <option :value="TipoNovedadConstants.ALTA">{{ TipoNovedadConstants.ALTA }}</option>
          <option :value="TipoNovedadConstants.BAJA">{{ TipoNovedadConstants.BAJA }}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-label">Tipo de licencia *</label>
        <select
          id="tipo_novedad"
          class="form-control readonly"
          disabled
          v-model="percepcionMaternidad.maternidad.tipo_licencia"
        >
          <option :value="null">Seleccione una opción</option>
          <option :value="TipoLicenciaMaternidadConstants.LIC_45_45">45 días Pre Parto y 45 días Post Parto</option>
          <option :value="TipoLicenciaMaternidadConstants.LIC_30_60">30 días Pre Parto y 60 días Post Parto</option>
        </select>
      </div>
      <pd-input
        v-model="percepcionMaternidad.maternidad.fecha_notificacion"
        id="fecha_notificacion"
        label="Fecha de notificacion al empleador de la declaración de opción de licencia *"
        type="date"
        readonly
      />
    </div>
    <div class="cols2">
      <pd-input
        v-model="percepcionMaternidad.maternidad.fecha_inicio"
        id="fecha_inicio"
        label="Fecha de inicio de licencia por maternidad *"
        type="date"
        readonly
      />
      <pd-input
        v-model="percepcionMaternidad.maternidad.fecha_fin"
        id="fecha_fin"
        label="Fecha de fin de licencia por maternidad *"
        type="date"
        readonly
      />
    </div>
  </div>
</template>
<script>
import {TipoParentezcoConstants} from "../constants/tipoParentezco";
import axios from "axios";
import FileLink from "@/components/elements/FileLink.vue";
import {TipoNovedadConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoNovedad";
import {
  TipoLicenciaMaternidadConstants
} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoLicenciaMaternidad";
import ShowPercepcionMaternidadCertificado
  from "@/modules/percepcionesAsignacionFamiliar/components/ShowPercepcionMaternidadCertificado.vue";

export default {
  name: "ShowPercepcionMaternidadMaternidad",
  components: {ShowPercepcionMaternidadCertificado, FileLink},
  computed: {
    TipoLicenciaMaternidadConstants() {
      return TipoLicenciaMaternidadConstants
    },
    TipoNovedadConstants() {
      return TipoNovedadConstants
    },
    TipoParentezcoConstants() {
      return TipoParentezcoConstants
    },
    acreditacion_conyuge() {
      return `${axios.defaults.baseURL}/${this.percepcionMaternidad.acreditacion_conyuge}`
    }
  },
  props: {
    percepcionMaternidad: {
      type: {},
      required: true
    }
  }
}
</script>

<style scoped>

</style>
